const frontPageTabs = () => {
  const btns = [...document.querySelectorAll(".tabs-nav a")];
  const tabsContainers = [
    ...document.querySelectorAll(".tabs-container > div")
  ];
  btns.forEach(btn => {
    btn.addEventListener("click", e => {
      e.preventDefault();
      removeActiveClass(btns);
      btn.classList.add("active");

      removeActiveClass(tabsContainers);
      tabsContainers[btns.indexOf(btn)].classList.add("active");
    });
  });
};

const removeActiveClass = elements => {
  elements.forEach(el => {
    el.classList.remove("active");
  });
};

export default frontPageTabs;
