//js
if (!global._babelPolyfill) {
  require("babel-polyfill");
}
require("./js/slider");
require("./js/scroll");
require("./js/helper");
require("./js/navigation");
require("./js/maps");
require("./js/main");
require("./js/swiper");
require("./js/fslightbox");

//fonts
require("./fonts/stylesheet.css");

// css
import "normalize.css";
require("./sass/style.scss");
