import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";
import frontPageTabs from "./frontPageTabs";
import productsAttributesSelection from "./woocommerce/productAttributesSelection";
import productQuantityHandler from "./woocommerce/productQuantityHandler";
import addToCartAjax from "./woocommerce/addToCartAjax";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  toggleMenu();

  addToCartAjax();

  const frontPageTabsContainer = document.querySelector(".tabs-container");
  if (frontPageTabsContainer) {
    frontPageTabs();
  }

  if (document.body.classList.contains("single-product")) {
    productsAttributesSelection();
  }

  if (document.querySelector(".quantity")) {
    productQuantityHandler();
  }

  searchBoxHandler();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};

const searchBoxHandler = () => {
  const searchBox = document.querySelector(".search-form-container");
  const openBtn = document.querySelector(".open-search-form");
  const closeBtn = document.querySelector(".close-search-form");
  openBtn.addEventListener("click", e => {
    e.preventDefault();
    searchBox.classList.toggle("open");
    closeBtn.addEventListener("click", e => {
      e.preventDefault();
      searchBox.classList.remove("open");
    });
  });
};
